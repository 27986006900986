// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-home-page-js": () => import("/opt/build/repo/src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-letstalk-page-js": () => import("/opt/build/repo/src/templates/letstalk-page.js" /* webpackChunkName: "component---src-templates-letstalk-page-js" */),
  "component---src-templates-results-page-js": () => import("/opt/build/repo/src/templates/results-page.js" /* webpackChunkName: "component---src-templates-results-page-js" */),
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-careers-index-js": () => import("/opt/build/repo/src/templates/careers/index.js" /* webpackChunkName: "component---src-templates-careers-index-js" */),
  "component---src-templates-careers-react-js": () => import("/opt/build/repo/src/templates/careers/react.js" /* webpackChunkName: "component---src-templates-careers-react-js" */),
  "component---src-templates-product-page-js": () => import("/opt/build/repo/src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-services-devops-js": () => import("/opt/build/repo/src/templates/services/devops.js" /* webpackChunkName: "component---src-templates-services-devops-js" */),
  "component---src-templates-services-index-js": () => import("/opt/build/repo/src/templates/services/index.js" /* webpackChunkName: "component---src-templates-services-index-js" */),
  "component---src-templates-services-mobile-js": () => import("/opt/build/repo/src/templates/services/mobile.js" /* webpackChunkName: "component---src-templates-services-mobile-js" */),
  "component---src-templates-careers-ios-js": () => import("/opt/build/repo/src/templates/careers/ios.js" /* webpackChunkName: "component---src-templates-careers-ios-js" */),
  "component---src-templates-services-pricing-js": () => import("/opt/build/repo/src/templates/services/pricing.js" /* webpackChunkName: "component---src-templates-services-pricing-js" */),
  "component---src-templates-services-salesforce-js": () => import("/opt/build/repo/src/templates/services/salesforce.js" /* webpackChunkName: "component---src-templates-services-salesforce-js" */),
  "component---src-templates-the-primoko-difference-index-js": () => import("/opt/build/repo/src/templates/the-primoko-difference/index.js" /* webpackChunkName: "component---src-templates-the-primoko-difference-index-js" */),
  "component---src-templates-the-primoko-difference-pricing-js": () => import("/opt/build/repo/src/templates/the-primoko-difference/pricing.js" /* webpackChunkName: "component---src-templates-the-primoko-difference-pricing-js" */),
  "component---src-templates-services-web-js": () => import("/opt/build/repo/src/templates/services/web.js" /* webpackChunkName: "component---src-templates-services-web-js" */),
  "component---src-templates-the-primoko-difference-process-js": () => import("/opt/build/repo/src/templates/the-primoko-difference/process.js" /* webpackChunkName: "component---src-templates-the-primoko-difference-process-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-team-js": () => import("/opt/build/repo/src/pages/about/team.js" /* webpackChunkName: "component---src-pages-about-team-js" */),
  "component---src-pages-about-why-js": () => import("/opt/build/repo/src/pages/about/why.js" /* webpackChunkName: "component---src-pages-about-why-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-the-primoko-difference-faqs-js": () => import("/opt/build/repo/src/pages/the-primoko-difference/faqs.js" /* webpackChunkName: "component---src-pages-the-primoko-difference-faqs-js" */),
  "component---src-pages-the-primoko-difference-manifesto-js": () => import("/opt/build/repo/src/pages/the-primoko-difference/manifesto.js" /* webpackChunkName: "component---src-pages-the-primoko-difference-manifesto-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

